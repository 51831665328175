import LuxonUtils from '@date-io/luxon';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getAddressForSSR } from 'components/Address/Address';
import { CookiesBanner } from 'components/CookiesBanner/CookiesBanner';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { NextPage } from 'next';
import Head from 'next/head';
import { Store } from 'pages/Store/Store';
import React from 'react';
import { wrapper } from 'state';
import { makeStoreFetchSuccessAction, makeStoreUpdateStoreOwnerInfoAction } from 'state/store/actions';
import { StoreState } from 'state/store/reducer';
import { makeTheme } from 'theme';
import { VendorContactType } from 'types';
import { StoreRestService } from 'services/StoreRestService';
import { CoordinatesCookieService } from 'services/CoordinatesCookieService';
import { AuthCookieService } from 'services/AuthCookieService';
import { getTranslationForEnum, TranslatableEnum } from '../../../src/components/T/GetTranslationForEnum';
import i18n from '../../../i18n';
import { getSeoTextForStore } from '../../../src/core/utils/utils';

const theme = makeTheme();

interface Props {
    storeState: StoreState;
    storeSlug: string;
    address: string;
    phone: string;
    i18nNamespaces: string[];
}

const Index: NextPage<Props> = ({ storeState, storeSlug, phone, address, i18nNamespaces }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <Head>
                <title>{`${storeState?.store?.name} ${address}`}</title>
                {storeSlug === 'bleskRamenskoe' && <link rel="manifest" href={`/customPWA/manifest.json`} />}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content={getSeoTextForStore(
                        storeState?.store?.address?.isoCode,
                        storeState?.store?.name,
                        getTranslationForEnum(TranslatableEnum.STORE_CATEGORY, storeState?.store?.storeCategory),
                        address,
                        phone,
                    )}
                />
                <meta name="og:title" content={`${storeState?.store?.name} ${address}`} />
                <meta
                    name="og:description"
                    content={getSeoTextForStore(
                        storeState?.store?.address?.isoCode,
                        storeState?.store?.name,
                        getTranslationForEnum(TranslatableEnum.STORE_CATEGORY, storeState?.store?.storeCategory),
                        address,
                        phone,
                    )}
                />
                <meta name="og:url" content={`https://any.market/${storeSlug}`} />
                <meta
                    property="og:image"
                    content={
                        storeState?.store?.image?.imageId
                            ? `https://any.market/api/images/${storeState?.store?.image?.imageId}`
                            : storeState?.store?.coverImage?.imageId
                            ? `https://any.market/api/images/${storeState?.store?.coverImage?.imageId}`
                            : 'https://any.market/og3.jpg'
                    }
                />
            </Head>

            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils} locale="ru">
                        <CssBaseline />
                        <ErrorBoundary>
                            <Store store={storeState} storeSlug={storeSlug as string} tab="catalog" />
                            <CookiesBanner />
                        </ErrorBoundary>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StylesProvider>
        </>
    );
};

const getFirstItem = <T extends {}>(value: T | T[]): T => (Array.isArray(value) ? value[0] : value);

export const getServerSideProps = wrapper.getServerSideProps(async ({ req, store, query }) => {
    const isLoggedIn = AuthCookieService.isLoggedIn(req);

    const coordinates = CoordinatesCookieService.getCoordinates(req);

    const jwt = AuthCookieService.getJwt(req);

    const storeSlug = Array.isArray(query.storeSlug) ? query.storeSlug[0] : query.storeSlug;

    const xForwardedFor = getFirstItem(req.headers['x-forwarded-for']);

    const headers = {
        Authorization: jwt,
        'X-Forwarded-For': xForwardedFor,
    };

    const storeDto = await StoreRestService.readStore(coordinates, storeSlug, headers);

    const { storeId, vendorId } = storeDto;

    // Fetching data in parallel
    const [categories, { products }, promos, userInfo] = await Promise.all([
        StoreRestService.readCategories(storeId, headers),
        StoreRestService.readProducts(storeId, headers),
        StoreRestService.readPromos(storeId, headers),
        isLoggedIn ? StoreRestService.readUserInfo(vendorId, headers) : Promise.resolve(null),
    ]);

    store.dispatch(makeStoreFetchSuccessAction(storeDto, products, categories, promos));

    // if (nextStart) {
    //     store.dispatch(makeStoreFetchNewProductsPageAction(storeId, nextStart));
    // }

    if (isLoggedIn && userInfo) {
        store.dispatch(makeStoreUpdateStoreOwnerInfoAction(userInfo.fake));
    }

    return {
        props: {
            storeState: store.getState().store,
            storeSlug,
            phone: (storeDto.contacts || []).find(contact => contact.type === VendorContactType.PHONE)?.value ?? null,
            address: getAddressForSSR(storeDto.address),
        },
    };
});

const Component = wrapper.withRedux(Index);

export default i18n.withTranslation(['feed', 'vendor', 'orders'])(Component);
